import React from "react";
import "./Hero.css";
const Hero = () => {
  return (
    <section className="main-hero" id="home">
      <div className="row">
        <div className="col-12 col-lg-12 col-md-12">
          <h1 className="text-white">
            <span className="title">Icy Grimsby</span>
          </h1>
          <a
            className="btn btn-dark m-auto d-block py-2 text-uppercase mt-4 px-5 fs-3"
            href="#store"
          >
            ORDER ONLINE
          </a>
        </div>
      </div>
    </section>
  );
};

export default Hero;
